
<template>
  <div>
    <div v-if="egressProfileMatter && matterCompetences.length > 1">
      <b-dropdown variant="none" dropleft size="sm" class="dropdown">
        <template v-for="competence in matterCompetences">
          <div
            :key="competence.id + '-' + study_unit_id"
            class="d-flex"
            style="width: 200px"
          >
            <b-badge
              class="mt-1 w-100 text-shortener"
              variant="light"
              style="text-align: left"
              v-b-tooltip.v-secondary.top.noninteractive="
                `${competence.full_sentence}`
              "
            >
              {{ competence.order }}.{{ cycleOrder(competence.cycle) }}
              {{ competence.full_sentence }}
            </b-badge>
            <b-button
              v-if="!competence.include_in_ra"
              class="dropdown-button"
              variant="none"
              @click="changedCompetenceInRaBase(competence.competence)"
              v-b-tooltip.v-secondary.noninteractive="
                `${
                  'Vincular ' +
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                  ' creado a la ' +
                  $getVisibleNames('mesh.competence', false, 'Competencia') +
                  '.'
                }`
              "
            >
              <b-icon icon="square" scale="1" variant="black"></b-icon>
            </b-button>
            <b-button
              class="dropdown-button"
              variant="none"
              v-else
              v-b-tooltip.v-secondary.noninteractive="
                `${
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                  ' vinculado  a la ' +
                  $getVisibleNames('mesh.competence', false, 'Competencia') +
                  '.'
                }`
              "
              @click="changedCompetenceInRaBase(competence.competence)"
            >
              <b-icon icon="check-square" scale="1" variant="black"></b-icon>
            </b-button>
          </div>
        </template>
      </b-dropdown>
    </div>
    <div v-else-if="egressProfileMatter && matterCompetences.length == 1">
      <b-button
        variant="none"
        size="sm"
        v-b-tooltip.v-secondary.noninteractive="
          `${
            !includeCompetenceInEPMatter
              ? 'Vincular ' +
                $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                ' creado a la ' +
                $getVisibleNames('mesh.competence', false, 'Competencia') +
                '.'
              : $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                ' vinculado  a la ' +
                $getVisibleNames('mesh.competence', false, 'Competencia') +
                '.'
          }`
        "
        @click="changedCompetenceInRaBase(matterCompetences[0].competence)"
      >
        <b-icon
          v-if="!includeCompetenceInEPMatter"
          icon="square"
          scale="1"
          variant="black"
        ></b-icon>
        <b-icon v-else icon="check-square" scale="1" variant="black"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "LinkingCompetenceToRa",
  components: {},
  props: {
    study_unit_id: {
      type: Number,
      required: true,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      egr_profile_ra_bases: names.EGR_PROFILE_RA_BASES,
      studyUnits: names.STUDY_UNITS,
      ra_bases: names.RA_BASES,
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    egressProfileMatter() {
      return this.egress_profile_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    studyUnit() {
      return this.studyUnits.find((x) => x.id == this.study_unit_id);
    },
    egressProfile() {
      if (!this.egressProfileMatter) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.egressProfileMatter.egress_profile
      );
    },
    egressProfileRaBase() {
      if (!this.egressProfileMatter) return null;
      return this.egr_profile_ra_bases.find(
        (x) =>
          x.egress_profile == this.egressProfileMatter.egress_profile &&
          x.study_unit == this.study_unit_id
      );
    },
    anyEgrProfileRaBase() {
      if (!this.egressProfileMatter) return null;
      return this.egr_profile_ra_bases.find(
        (x) => x.study_unit == this.study_unit_id
      );
    },
    raBase() {
      if (!this.egressProfileRaBase) return null;
      return this.ra_bases.find(
        (x) => x.id == this.egressProfileRaBase.base_ra
      );
    },
    matterCompetences() {
      let list = [];
      if (
        !this.egressProfileMatter &&
        !this.egressProfile &&
        !this.profile_competences &&
        !this.competences
      )
        return list;
      this.egressProfileMatter.competences.forEach((element) => {
        let get_competence = this.competences.find((x) => x.id == element);
        if (get_competence) {
          let instance = this.profile_competences.find(
            (x) =>
              x.competence == get_competence.id &&
              x.egress_profile == this.egressProfile.id
          );
          if (instance) {
            list.push({
              id: instance.id,
              order: instance.order,
              egress_profile: instance.egress_profile,
              competence: instance.competence,
              cycle: instance.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              include_in_ra: this.raBase
                ? this.raBase.competences.includes(instance.competence)
                : false,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.cycle > b.cycle) return 1;
        if (a.cycle < b.cycle) return -1;
        return 0;
      });
    },
    includeCompetenceInEPMatter() {
      if (!this.raBase && !this.egressProfileMatter) return false;
      else if (
        this.raBase &&
        this.matterCompetences.filter((x) =>
          this.raBase.competences.includes(x.competence)
        ).length > 0
      )
        return true;
      else return false;
    },
  },
  methods: {
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.egressProfile) return "-";
      let cycleOrder = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == this.egressProfile.id
      );
      return cycleOrder ? cycleOrder.order : "-";
    },
    changedCompetenceInRaBase(competence_id) {
      if (this.raBase) {
        let competences = this.raBase.competences;
        const index = competences.findIndex((x) => x == competence_id);
        if (index != -1) {
          competences.splice(index, 1);
          this.$store
            .dispatch(names.PATCH_RA_BASE, {
              ra_base_id: this.raBase.id,
              item: { competences: competences },
            })
            .then(() => {
              toast(
                this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
                  " desvinculado."
              );
            });
        } else {
          competences.push(competence_id);
          this.$store
            .dispatch(names.PATCH_RA_BASE, {
              ra_base_id: this.raBase.id,
              item: { competences: competences },
            })
            .then(() => {
              toast(
                this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
                  " vinculado."
              );
            });
        }
      } else if (this.studyUnit) {
        if (!this.egressProfileRaBase && !this.anyEgrProfileRaBase) {
          this.$store
            .dispatch(names.POST_RA_BASE, {
              verb: this.studyUnit.verb,
              competences: [competence_id],
              action: this.studyUnit.action,
              content: this.studyUnit.content,
              condition: this.studyUnit.condition,
              full_sentence: this.studyUnit.full_sentence,
              order:
                this.ra_bases.filter((x) =>
                  x.competences.includes(competence_id)
                ).length + 1,
            })
            .then((ra_base) => {
              this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, {
                egress_profile: this.egressProfileMatter.egress_profile,
                base_ra: ra_base.id,
                study_unit: this.studyUnit.id,
              });
            });
        } else if (this.anyEgrProfileRaBase) {
          this.$store
            .dispatch(names.FETCH_RA_BASE, this.anyEgrProfileRaBase.base_ra)
            .then((ra_base) => {
              let competences = ra_base.competences;
              const index = competences.findIndex((x) => x == competence_id);
              if (index != -1) {
                this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, {
                  egress_profile: this.egressProfileMatter.egress_profile,
                  base_ra: ra_base.id,
                  study_unit: this.studyUnit.id,
                });
                toast(
                  this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
                    " desvinculado."
                );
              } else {
                competences.push(competence_id);
                this.$store
                  .dispatch(names.PATCH_RA_BASE, {
                    ra_base_id: ra_base.id,
                    item: { competences: competences },
                  })
                  .then(() => {
                    this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, {
                      egress_profile: this.egressProfileMatter.egress_profile,
                      base_ra: ra_base.id,
                      study_unit: this.studyUnit.id,
                    });
                    toast(
                      this.$getVisibleNames(
                        "teaching.ramicro",
                        false,
                        "RA Micro"
                      ) + " vinculado."
                    );
                  });
              }
            });
        }
      }
    },
  },
  mounted() {},
  created() {
    // this.$store.dispatch(names.FETCH_EGR_PROFILE_RA_BASES, {
    //   study_unit_id: this.study_unit_id,
    // });
  },
};
</script>

<style scoped>
.text-shortener {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown >>> button {
  scale: 0.7;
  padding: 0px 6px 0px 6px !important;
  margin: 0px;
  margin-top: 1px;
  border: solid 1px black;
}
.dropdown-button {
  border: none !important;
}
</style>