var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.egressProfileMatter && _vm.matterCompetences.length > 1)?_c('div',[_c('b-dropdown',{staticClass:"dropdown",attrs:{"variant":"none","dropleft":"","size":"sm"}},[_vm._l((_vm.matterCompetences),function(competence){return [_c('div',{key:competence.id + '-' + _vm.study_unit_id,staticClass:"d-flex",staticStyle:{"width":"200px"}},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `${competence.full_sentence}`
            ),expression:"\n              `${competence.full_sentence}`\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"mt-1 w-100 text-shortener",staticStyle:{"text-align":"left"},attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(competence.order)+"."+_vm._s(_vm.cycleOrder(competence.cycle))+" "+_vm._s(competence.full_sentence)+" ")]),(!competence.include_in_ra)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${
                'Vincular ' +
                _vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                ' creado a la ' +
                _vm.$getVisibleNames('mesh.competence', false, 'Competencia') +
                '.'
              }`
            ),expression:"\n              `${\n                'Vincular ' +\n                $getVisibleNames('teaching.ramicro', false, 'RA Micro') +\n                ' creado a la ' +\n                $getVisibleNames('mesh.competence', false, 'Competencia') +\n                '.'\n              }`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"dropdown-button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.changedCompetenceInRaBase(competence.competence)}}},[_c('b-icon',{attrs:{"icon":"square","scale":"1","variant":"black"}})],1):_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${
                _vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                ' vinculado  a la ' +
                _vm.$getVisibleNames('mesh.competence', false, 'Competencia') +
                '.'
              }`
            ),expression:"\n              `${\n                $getVisibleNames('teaching.ramicro', false, 'RA Micro') +\n                ' vinculado  a la ' +\n                $getVisibleNames('mesh.competence', false, 'Competencia') +\n                '.'\n              }`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"dropdown-button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.changedCompetenceInRaBase(competence.competence)}}},[_c('b-icon',{attrs:{"icon":"check-square","scale":"1","variant":"black"}})],1)],1)]})],2)],1):(_vm.egressProfileMatter && _vm.matterCompetences.length == 1)?_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
        `${
          !_vm.includeCompetenceInEPMatter
            ? 'Vincular ' +
              _vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro') +
              ' creado a la ' +
              _vm.$getVisibleNames('mesh.competence', false, 'Competencia') +
              '.'
            : _vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro') +
              ' vinculado  a la ' +
              _vm.$getVisibleNames('mesh.competence', false, 'Competencia') +
              '.'
        }`
      ),expression:"\n        `${\n          !includeCompetenceInEPMatter\n            ? 'Vincular ' +\n              $getVisibleNames('teaching.ramicro', false, 'RA Micro') +\n              ' creado a la ' +\n              $getVisibleNames('mesh.competence', false, 'Competencia') +\n              '.'\n            : $getVisibleNames('teaching.ramicro', false, 'RA Micro') +\n              ' vinculado  a la ' +\n              $getVisibleNames('mesh.competence', false, 'Competencia') +\n              '.'\n        }`\n      ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.changedCompetenceInRaBase(_vm.matterCompetences[0].competence)}}},[(!_vm.includeCompetenceInEPMatter)?_c('b-icon',{attrs:{"icon":"square","scale":"1","variant":"black"}}):_c('b-icon',{attrs:{"icon":"check-square","scale":"1","variant":"black"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }